const Svg = () => (
    <svg width="18px" height="18px" version="1.0" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(0 40) scale(.1 -.1)">
            <path d="m145 355c-115-41-147-185-61-271 66-66 166-66 232 0 83 83 58 218-50 267-49 22-68 23-121 4zm146-64c35-35 39-44 39-91s-4-56-39-91-44-39-91-39-56 4-91 39-39 44-39 91 4 56 39 91 44 39 91 39 56-4 91-39z" />
            <path d="m134 256c-9-23 0-51 16-51 10 0 15 10 15 29 0 32-21 47-31 22z" />
            <path d="m234 256c-9-23 0-51 16-51 10 0 15 10 15 29 0 32-21 47-31 22z" />
            <path d="m140 157c0-39 83-52 114-18 22 24 6 35-29 19-21-9-31-9-46 0-24 16-39 15-39-1z" />
        </g>
    </svg>
);


export default Svg;
