import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scrollTo } from 'helpers/helpers';

const useFormState = (defaultState, requiredFields = []) => {
  const { t } = useTranslation();
  const isRequired = (key) => requiredFields.findIndex((field) => key == field) > -1;
  const [formState, setFormState] = useState(
    Object.keys(defaultState).reduce(
      (result, key) => ({
        ...result,
        [key]: {
          value: defaultState[key],
          dirty: false,
          error: '',
          required: isRequired(key),
        },
      }),
      {}
    )
  );

  // Update state by passing key and value
  const setFormStateValue = (key, value) =>
    setFormState((prevState) => ({
      ...prevState,
      [key]: { ...prevState[key], value, dirty: true, error: '' },
    }));

  // given an object of the same format as the defaultState
  // update only the included properties of the internal hook state
  // useful for setting from server
  const setFormStateValues = (newState, dirty = false) => {
    setFormState(
      Object.keys(formState).reduce(
        (result, key) => ({
          ...result,
          [key]: { value: newState[key], dirty, error: '' },
        }),
        {}
      )
    );
  };

  // Update state in a fashion similar to traditional setState()
  // pass an object of key value pairs you want to update
  const setPrevStateValues = (updatedState = {}, dirty = true) =>
    setFormState((prevState) => ({
      ...prevState,
      ...Object.keys(updatedState).reduce(
        (result, key) => ({
          ...result,
          [key]: { value: updatedState[key], dirty, error: '' }, // todo: should we add an `onChange` function here so the input could specify it without the need for a key?
        }),
        {}
      ),
    }));

  // [{ field: "name", "message": "Must include capitals"}]
  // populates the error property of corresponding form key
  const setFormStateErrors = (errors = {}) => {
    const newFormState = { ...formState };
    for (const error of errors) {
      if (newFormState[error?.field]) {
        newFormState[error?.field].error = error?.message;
      }
    }
    scrollTo(0, 0);
    setFormState(newFormState);
  };

  // has something changed in this state? useful for button disabled
  // ignore param is an array of keys to ignore
  // mostly for RichTextEditor because its always dirty...
  const isDirty = (ignore = []) =>
    Object.entries(formState)
      .filter((entry) => ignore.findIndex((key) => key === entry[0]) === -1)
      .some((entry) => !!entry[1].dirty);

  // Convert state to an object of changed values
  const toPayload = (onlyDirty = true) =>
    Object.keys(formState).reduce(
      (a, key) =>
        onlyDirty && !formState[key].dirty ? { ...a } : { ...a, [key]: formState[key]?.value },
      {}
    );

  const requiredFieldsFilled = () => {
    const errors = Object.keys(formState)
      .filter((key) => formState[key].required)
      .reduce(
        (a, key) =>
          formState[key].value
            ? [...a]
            : [
                ...a,
                {
                  field: key,
                  message: t('global/errors/field-required', { field: t(key) || key }),
                },
              ],
        []
      );

    if (Object.keys(errors).length) {
      setFormStateErrors(errors);
      return false;
    }
    return true;
  };

  const errorList = Object.keys(formState)
    .filter((key) => formState[key].error)
    .map((key) => formState[key].error);

  // If the form contains an image/file, convert the
  // entire JSON to a form so server submission works
  const toFormData = (payload) => {
    const formData = new FormData();
    for (const key in payload) {
      if (typeof payload[key] == 'object' && !(payload[key] instanceof File)) {
        const arr = payload[key];
        for (let i = 0; i < arr.length; i++) {
          if (typeof arr[i] === 'object') {
            for (let property in arr[i]) {
              formData.append(`${key}[${i}]${property}`, arr[i][property]);
            }
          } else {
            formData.append(`${key}[${i}]`, arr[i]);
          }
        }
      } else {
        formData.append(key, payload[key]);
      }
    }
    return formData;
  };

  return {
    formState,
    setFormStateValue,
    setFormStateValues,
    setPrevStateValues,
    setFormStateErrors,
    isDirty,
    toPayload,
    toFormData,
    requiredFieldsFilled,
    errorList,
  };
};

export default useFormState;
