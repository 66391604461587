import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MetaTags = ({
  title,
  description,
  image,
  viewport,
  canonical,
  favicon,
  google_analytics_tracking_id,
  facebook_pixel_id,
}) => {
  return (
    <Helmet>
      {/* Title */}
      {title && <title>{title}</title>}
      {title && <meta name="title" content={title} />}
      {title && <meta property="og:title" content={title} />}
      {title && <meta name="twitter:title" content={title} />}

      {/* Description */}
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {description && <meta name="twitter:description" content={description} />}

      {/* Image */}
      {image && <meta property="og:image" content={image} />}
      {image && <meta name="twitter:image" content={image} />}

      {/* Canonical */}
      {canonical && <link rel="canonical" href={canonical} />}
      {canonical && <meta property="og:url" content={canonical} />}

      {/* Viewport */}
      {viewport && <meta name="viewport" content={viewport} />}

      {/* Favicon */}
      {favicon && <link rel="icon" type="image/x-icon" href={favicon} />}
      {favicon && <link rel="apple-touch-icon" href={favicon} />}

      {/* Google Analytics */}
      {google_analytics_tracking_id && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${google_analytics_tracking_id}`}
        />
      )}
      {google_analytics_tracking_id && (
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${google_analytics_tracking_id}');
          `}
        </script>
      )}

      {/* Facebook Pixel */}
      {facebook_pixel_id && (
        <script>
          {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${facebook_pixel_id}');
          fbq('track', 'PageView');
        `}
        </script>
      )}
    </Helmet>
  );
};

MetaTags.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  viewport: PropTypes.string,
  canonical: PropTypes.string,
  favicon: PropTypes.string,
  google_analytics_tracking_id: PropTypes.string,
  facebook_pixel_id: PropTypes.string,
};

MetaTags.defaultProps = {
  title: null,
  description: null,
  image: null,
  viewport: null,
  canonical: null,
  favicon: null,
  google_analytics_tracking_id: null,
  facebook_pixel_id: null,
};

export default MetaTags;
