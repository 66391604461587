import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const Svg = ({ classes }) => (
  <svg
    className={classes}
    width="28"
    height="41"
    viewBox="0 0 28 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.6028 13.6667C27.6028 20.7284 23.3099 27.1227 15.5274 38.5635C14.733 39.7314 13.0085 39.7314 12.214 38.5635C4.43154 27.1227 0.138672 20.7284 0.138672 13.6667C0.138672 6.11878 6.28672 0 13.8707 0C21.4547 0 27.6028 6.11878 27.6028 13.6667Z"
      fill="#0284C7"
    />
    <ellipse cx="13.8709" cy="14" rx="11.7703" ry="12" fill="#075985" />
  </svg>
);

Svg.propTypes = {
  classes: PropTypes.string,
};

Svg.defaultProps = {
  classes: theme.colors.Grey400,
};

export default Svg;
