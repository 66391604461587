import i18n from 'i18n';
import moment from 'moment';
import 'moment/min/locales.min';
import { roundDecimal } from './helpers';

export const DATABASE_DATE_FORMAT = 'YYYY-MM-DD';
export const DAY_MONTH_YEAR_FORMAT = 'DD/MM/YYYY';
export const FULL_DATETIME_FORMAT = 'dddd, MMMM D, YYYY [at] h:mma';
export const DAY_OF_WEEK_MONTH_DAY = 'ddd, MMM DD'; // Wed, May 17
export const DAY_OF_WEEK_MONTH_DAY_LONG = 'dddd, MMM DD'; // Wednesday, Feb 17
export const DAY_OF_WEEK_LONG_MONTH_LONG_DAY_YEAR = 'dddd, MMMM DD, YYYY'; // Wednesday, February 17 2022
export const DAY_OF_WEEK = 'dddd'; // Wednesday
export const DAY_OF_WEEK_MONTH_DAY_YEAR = 'ddd, MMM DD YYYY'; // Wed, May 17 2022

export const getCurrencySymbol = (currency, currencyDisplay = 'symbol') => {
  const locale = i18n.language;

  const symbol = (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      currencyDisplay,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

  return symbol;
};

export const formatMoney = (value, currency) => {
  const formattedValue = parseFloat(roundDecimal(value));
  if (Number.isNaN(formattedValue)) return '-';

  const locale = i18n.language;
  const options = {
    style: 'currency',
    currency,
  };

  return formattedValue.toLocaleString(locale, options);
};

export const formatDate = (date, format = 'L') => {
  if (!date) return '';

  const locale = i18n.language;

  moment.locale(locale);
  const newDate = moment(date).format(format);
  return newDate;
};

export const formatTime = (time) => {
  if (!time) return '';

  // when time cannot be parsed directly, create a fixed date since it will be discarded later
  const formattedTime = !Number.isNaN(new Date(time).getTime())
    ? new Date(time)
    : new Date(`${new Date().getFullYear()}-01-01T${time}`);

  const locale = i18n.language;
  let options = {};

  options = {
    ...options,
    hour: '2-digit',
    minute: '2-digit',
  };

  return formattedTime.toLocaleTimeString(locale, options);
};

export const formatDateTime = (datetime, format) => {
  if (!datetime) return '';
  const locale = i18n.language;

  if (format) {
    moment.locale(locale);

    if (typeof datetime === 'string' && !datetime.includes('T'))
      return moment.utc(datetime).format(format);
    else return moment(datetime).format(format);
  }

  const formattedDateTime = new Date(datetime);
  let options = {};

  const ignoreTimezone = typeof formattedDateTime === 'string' && !formattedDateTime.includes('T');

  if (ignoreTimezone) {
    options = {
      ...options,
      timeZone: 'UTC',
    };
  }

  options = {
    ...options,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  return formattedDateTime.toLocaleDateString(locale, options);
};

export const importMomentLocaleFile = async () => {
  try {
    await import(`moment/locale/${i18n.language.toLowerCase()}`);
  } catch (error) {
    console.warn(
      `Could not load moment locale file for ${i18n.language}, using en-US instead`,
      error
    );
  }
};

export const setLanguage = (language) => {
  if (language && i18n.language !== language) {
    i18n.changeLanguage(language);
    localStorage.setItem('i18nextLng', language);
  }
};
