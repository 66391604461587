import { CircularLoadingBar, SpinnerSmall } from 'assets/svgIcons';
import React, { useEffect, useState, Suspense, lazy } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { setThemeDefault } from 'styles/utils';
import { iOS, getScreenSize, getDomainParts } from 'helpers/helpers';
import { importMomentLocaleFile } from 'helpers/localeHelpers';
import Impersonate from 'components/Impersonate';
import MetaTags from 'components/MetaTags';
import Toast from './components/Toast';
import { screenSizeAtom, loadingProgressAtom } from './state/appState';
import './styles/index.css';

const Backoffice = lazy(() => import('pages/backoffice/Backoffice'));
const Storefront = lazy(() => import('pages/storefront/Storefront'));
const ConnectVendor = lazy(() => import('pages/backoffice/ConnectVendor'));

const App = () => {
  const setScreenSize = useSetRecoilState(screenSizeAtom);
  const [loadingProgress] = useRecoilState(loadingProgressAtom);
  const [runningOniOS, setiOSStatus] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  const subdomainRedirects = () => {
    // if the subdomain matches one of the special routes
    // perform a redirect on domain root.
    const { subdomain } = getDomainParts();
    if (pathname === '/') {
      if (['login', 'accounts'].includes(subdomain?.toLowerCase())) {
        history.push('/backoffice');
      }
      if (['register'].includes(subdomain?.toLowerCase())) {
        history.push('/backoffice/register/trial');
      }
    }
  };

  useEffect(async () => {
    setiOSStatus(iOS());
    // Timeout because of iOS bug; if iPhone, screen size
    // doubled on first load. Messes up which components
    // render until browser resize :(
    setTimeout(() => setScreenSize(getScreenSize()), 1000);
    window.addEventListener('resize', () => setScreenSize(getScreenSize()));
    subdomainRedirects();
    importMomentLocaleFile();
    setThemeDefault();
  }, []);

  /* https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone/46254706#46254706 */
  const seoProps = {
    viewport: `width=device-width, initial-scale=1${
      runningOniOS ? ', maximum-scale=1, viewport-fit=cover' : ''
    }`,
  };

  return (
    <div className="App">
      <Toast />
      <MetaTags {...seoProps} />
      {loadingProgress && (
        <div className="loading-overlay">
          <div className="center">
            <CircularLoadingBar />
          </div>
        </div>
      )}
      <Suspense
        fallback={
          <span>
            <SpinnerSmall />
          </span>
        }>
        <Switch>
          <Route path="/impersonate">
            <Impersonate />
          </Route>
          <Route path="/backoffice">
            <Backoffice />
          </Route>
          <Route path="/connect">
            <ConnectVendor />
          </Route>
          <Route path="/">
            <Storefront />
          </Route>
        </Switch>
      </Suspense>
    </div>
  );
};

export default App;
